import { React, useRef, useState, useEffect } from "react";

import logo from "../Images/logo.webp";

import "./new-register.css";
import "./new-redeemption.css";

import "bootstrap/dist/css/bootstrap.min.css";
import secureLocalStorage from "react-secure-storage";

import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

// import ReCAPTCHA from "react-google-recaptcha";
import UserformService from "../services/userform.service";

import $ from "jquery";

import { toast } from "react-toastify";
import { useLayoutEffect } from "react";
import "select2/dist/css/select2.min.css";
import "select2";

function RegisterForm(props) {
  const TITLE = process.env.REACT_APP_API_SITE_TITLE + " | Registration Form";
  const [fields, setFields] = useState({
    sales_name: "",
    store_location: "",
    name: "",
    lname: "",
    emailid: "",
    address: "",
    p_address: "",
    city: "",
    state: "",
    zipcode: "",
    phone: "",
    captcha: "",
  });

  const [errors, setErrors] = useState({});
  const [captcha, setCaptcha] = useState(false);
  const [states, setStates] = useState([]);
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);

  const [value_userform, setValue_userform] = useState([]);

  const navigate = useNavigate();

  secureLocalStorage.removeItem("registerform");
  useEffect(() => {
    async function getStates() {
      let errors = {};

      try {
        const response = await UserformService.getStates();

        var states = response.data.response;
        const results = [];
        states.map((value) => {
          return results.push({
            value: value.code,
            label: value.name,
          });
        });
        await setStates([...results]);

        errors["voucher"] = response.data.response;
      } catch (err) {
        if (err.status === 429 || err.status === "429") {
          alert("Max Limit Reached. Please wait.");
        }
      }
    }
    getStates();

    async function getLocations() {
      let errors = {};

      try {
        const response = await UserformService.getStoreLocation();

        var locations = response.data.response;
        const results = [];
        locations.map((value) => {
          return results.push({
            value: value.id,
            label: value.store_name,
          });
        });
        await setLocations([...results]);

        errors["voucher"] = response.data.response;
      } catch (err) {
        if (err.status === 429 || err.status === "429") {
          alert("Max Limit Reached. Please wait.");
        }
      }
    }
    getLocations();
  }, []);
  const ref = useRef();

  const pasted = (e) => {
    e.preventDefault();
    return false;
  };

  const onlyNumbers = (evt) => {
    const { name, value } = evt.target;
    evt.target.value = evt.target.value.replace(/[^0-9]+/g, "");

    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: evt.target.value,
      };
    });
  };

  const handleChange = (e) => {
    let { value, name } = e.target;
    if (name === "state" && value === "true") {
      value = "";
    }
    // if (name === "store_location" && value === "true") {
    //   value = "";
    // }

    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };
  const saveErrors = (err) => {
    setErrors(err);
  };

  const submituserRegistrationForm = (e) => {
    e.preventDefault();
    let data = {
      sales_name: fields?.sales_name,
      store_id: fields?.store_location,
      first_name: fields?.name,
      last_name: fields?.lname,
      email: fields?.emailid,
      address1: fields?.address,
      address2: fields?.p_address,
      city: fields?.city,
      state: fields?.state,
      zip: fields?.zipcode,
      phone: fields?.phone,
      // captcha: captcha,
    };

    try {
      setLoading(true);
      const responseuserform = UserformService.regData(data);
      responseuserform
        .then((response) => {
          var valueUserform = response?.data?.status;
          setValue_userform(valueUserform);

          secureLocalStorage.setItem(
            "registerform",
            JSON.stringify({
              sales_name: fields.sales_name,
              store_location: fields.store_location,
              name: fields.name,
              lname: fields.lname,
              emailid: fields.emailid,
              address: fields.address,
              p_address: fields.p_address,
              city: fields.city,
              state: fields.state,
              zipcode: fields.zipcode,
              phone: fields.phone,
              // captcha: captcha,
            })
          );
          localStorage.removeItem("return_page");
          localStorage.setItem("isRegister", true);
          navigate("/register/registration-thankyou");

          if (value_userform?.includes("redirect")) {
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);

          if (
            err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
          ) {
            toast.error(err?.response?.data?.message, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }

          let errorData = {};
          errorData["sales_name"] =
            err?.response?.data &&
            err?.response?.data.sales_name &&
            err?.response?.data.sales_name[0];
          errorData["store_location"] =
            err?.response?.data &&
            err?.response?.data.store_id &&
            err?.response?.data.store_id[0];
          errorData["name"] =
            err?.response?.data &&
            err?.response?.data.first_name &&
            err?.response?.data.first_name[0];
          errorData["lname"] =
            err?.response?.data &&
            err?.response?.data.last_name &&
            err?.response?.data.last_name[0];
          errorData["emailid"] =
            err?.response?.data &&
            err?.response?.data.email &&
            err?.response?.data.email[0];
          errorData["address"] =
            err?.response?.data &&
            err?.response?.data.address1 &&
            err?.response?.data.address1[0];
          errorData["p_address"] =
            err?.response?.data &&
            err?.response?.data.address2 &&
            err?.response?.data.address2[0];
          errorData["city"] =
            err?.response?.data &&
            err?.response?.data.city &&
            err?.response?.data.city[0];
          errorData["state"] =
            err?.response?.data &&
            err?.response?.data.state &&
            err?.response?.data.state[0];
          errorData["zipcode"] =
            err?.response?.data &&
            err?.response?.data.zip &&
            err?.response?.data.zip[0];
          errorData["phone"] =
            err?.response?.data &&
            err?.response?.data.phone &&
            err?.response?.data.phone[0];
          errorData["checkederrortwo"] =
            err?.response?.data &&
            err?.response?.data.size &&
            err?.response?.data.size[0];
          errorData["checkederrorcaptcha"] =
            err?.response?.data &&
            err?.response?.data.captcha &&
            err?.response?.data.captcha[0];

          saveErrors(errorData);
          setTimeout(() => {
            ref?.current?.focus();
          }, 200);
        });
    } catch (err) {
      alert(err);
      if (err.response.status === 429 || err.response.status === "429") {
        alert("Max Limit Reached. Please wait.");
      }
      let errorData = {};
      errorData["sales_name"] = err?.response?.data?.sales_name[0];
      errorData["store_location"] = err?.response?.data?.store_id[0];
      errorData["name"] = err?.response?.data?.first_name[0];
      errorData["lname"] = err?.response?.data?.last_name[0];
      errorData["emailid"] = err?.response?.data?.phone[0];
      errorData["address"] = err?.response?.data?.address1[0];
      errorData["p_address"] = err?.response?.data?.address2[0];
      errorData["city"] = err?.response?.data?.city[0];
      errorData["state"] = err?.response?.data?.state[0];
      errorData["phone"] = err?.response?.data?.phone[0];
      errorData["zipcode"] = err?.response?.data?.zip[0];

      errorData["checkederrorcaptcha"] =
        err?.response?.data?.message.captcha[0];
      saveErrors(errorData);
    }
  };

  // function onCaptchaChange(value) {
  //   if (value === null) {
  //     setCaptcha(false);
  //   } else {
  //     setCaptcha(true);
  //   }
  // }

  const Select2Field = ({ name, label, options, value }) => {
    const selectRef = useRef();

    useLayoutEffect(() => {
      const $select = $(selectRef.current);

      $select.select2();

      $select.on("change", (event) => {
        const selectedValue = $(event.target).val();
        setFields((prevValue) => {
          return {
            ...prevValue,
            [name]: selectedValue,
          };
        });
      });

      return () => {
        $select.select2("destroy");
        $select.off("change");
      };
    }, []);

    return (
      <>
        <select
          ref={selectRef}
          name={name}
          className="form-select register-select2"
          value={value || ""}
          id={name}
        >
          <option value="" disabled={true} selected={true}>
            Select {label}
          </option>
          {options.map((opt, index) => (
            <option key={index} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </select>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <section className="form-body-center form-section-bg new-register">
        <div className="container">
          <div className="row gy-4 align-items-center">
            <div className="col-12 col-md-12 col-xl-12">
              <div className="register-logo">
                <img
                  src={logo}
                  alt="city-logo.png"
                  className="img-fluid"
                  width="360"
                  height="118"
                />
              </div>
            </div>
            <div className="col-12 col-md-12 col-xl-12">
              <div className="d-flex justify-content-center text-bg-primary top-bg">
                <div className="col-12 col-xl-12 text-center">
                  <h2 className="form-heading">
                    Register Customer for a Chance to Win!
                  </h2>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-12 col-xl-12">
              <div className="card border-0 rounded-4 p-0">
                <div className="card-body p-3 p-md-4 p-xl-5">
                  <form
                    onSubmit={submituserRegistrationForm}
                    name="userRegistrationForm"
                  >
                    <div className="row gy-3 overflow-hidden">
                      <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-account-circle icon-position"></i>

                          <input
                            type="text"
                            className="form-control"
                            style={
                              errors.sales_name
                                ? { border: "1px solid red" }
                                : null
                            }
                            name="sales_name"
                            placeholder="Sales Associate Name"
                            ref={errors.sales_name ? ref : null}
                            value={fields.sales_name || ""}
                            autoFocus=""
                            onChange={handleChange}
                          />

                          <label className="form-label">
                            Sales Associate Name
                            <span className="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg">{errors.sales_name}</div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-pin icon-position"></i>

                          <Select2Field
                            name="store_location"
                            label="Location"
                            options={locations}
                            value={fields["store_location"] || ""}
                          />

                          <label className="form-label form-label-select">
                            Store Location
                            <span className="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg">
                          {errors.store_location && !errors.sales_name
                            ? errors.store_location
                            : null}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-account-o icon-position"></i>

                          <input
                            type="text"
                            className="form-control"
                            style={
                              errors.name &&
                              !errors.store_location &&
                              !errors.sales_name
                                ? { border: "1px solid red" }
                                : null
                            }
                            name="name"
                            placeholder="Customer First Name"
                            ref={
                              errors.name &&
                              !errors.store_location &&
                              !errors.sales_name
                                ? ref
                                : null
                            }
                            value={fields.name || ""}
                            autoFocus=""
                            onChange={handleChange}
                          />

                          <label className="form-label">
                            Customer First Name
                            <span className="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg">
                          {errors.name &&
                          !errors.store_location &&
                          !errors.sales_name
                            ? errors.name
                            : null}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                        <div className="form-floating">
                          <i className="zmdi zmdi-account-o icon-position"></i>

                          <input
                            type="text"
                            className="form-control"
                            name="lname"
                            style={
                              errors.lname &&
                              !errors.name &&
                              !errors.store_location &&
                              !errors.sales_name
                                ? { border: "1px solid red" }
                                : null
                            }
                            placeholder="Customer Last Name"
                            value={fields.lname || ""}
                            ref={
                              errors.lname &&
                              !errors.name &&
                              !errors.store_location &&
                              !errors.sales_name
                                ? ref
                                : null
                            }
                            onChange={handleChange}
                          />

                          <label className="form-label">
                            Customer Last Name
                            <span className="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg">
                          {errors.lname &&
                          !errors.name &&
                          !errors.store_location &&
                          !errors.sales_name
                            ? errors.lname
                            : null}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-email-open icon-position"></i>

                          <input
                            type="text"
                            className="form-control"
                            name="emailid"
                            ref={
                              errors.emailid &&
                              !errors.lname &&
                              !errors.name &&
                              !errors.store_location &&
                              !errors.sales_name
                                ? ref
                                : null
                            }
                            style={
                              errors.emailid &&
                              !errors.lname &&
                              !errors.name &&
                              !errors.store_location &&
                              !errors.sales_name
                                ? { border: "1px solid red" }
                                : null
                            }
                            placeholder="Customer Email"
                            value={fields.emailid || ""}
                            onChange={handleChange}
                          />

                          <label className="form-label">
                            Customer Email
                            <span className="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg">
                          {errors.emailid &&
                          !errors.lname &&
                          !errors.name &&
                          !errors.store_location &&
                          !errors.sales_name
                            ? errors.emailid
                            : null}
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-store  icon-position"></i>

                          <input
                            type="text"
                            className="form-control"
                            placeholder="Customer Address"
                            name="address"
                            style={
                              errors.address &&
                              !errors.emailid &&
                              !errors.lname &&
                              !errors.name &&
                              !errors.store_location &&
                              !errors.sales_name
                                ? { border: "1px solid red" }
                                : null
                            }
                            value={fields.address || ""}
                            ref={
                              errors.address &&
                              !errors.emailid &&
                              !errors.lname &&
                              !errors.name &&
                              !errors.store_location &&
                              !errors.sales_name
                                ? ref
                                : null
                            }
                            onChange={handleChange}
                          />

                          <label className="form-label">
                            Customer Address
                            <span className="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg">
                          {errors.address &&
                          !errors.emailid &&
                          !errors.lname &&
                          !errors.name &&
                          !errors.store_location &&
                          !errors.sales_name
                            ? errors.address
                            : null}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-store icon-position"></i>

                          <input
                            type="text"
                            className="form-control"
                            placeholder="Apartment/Suite #"
                            name="p_address"
                            value={fields.p_address || ""}
                            onChange={handleChange}
                          />

                          <label className="form-label">
                            Apartment/Suite # (Optional)
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-city  icon-position"></i>

                          <input
                            type="text"
                            className="form-control"
                            placeholder="City"
                            name="city"
                            style={
                              errors.city &&
                              !errors.address &&
                              !errors.emailid &&
                              !errors.lname &&
                              !errors.name &&
                              !errors.store_location &&
                              !errors.sales_name
                                ? { border: "1px solid red" }
                                : null
                            }
                            value={fields.city || ""}
                            ref={
                              errors.city &&
                              !errors.address &&
                              !errors.emailid &&
                              !errors.lname &&
                              !errors.name &&
                              !errors.store_location &&
                              !errors.sales_name_l &&
                              !errors.sales_name
                                ? ref
                                : null
                            }
                            onChange={handleChange}
                          />

                          <label className="form-label">
                            City<span className="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg">
                          {errors.city &&
                          !errors.address &&
                          !errors.emailid &&
                          !errors.lname &&
                          !errors.name &&
                          !errors.store_location &&
                          !errors.sales_name
                            ? errors.city
                            : null}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-my-location icon-position"></i>

                          <Select2Field
                            name="state"
                            label="State"
                            options={states}
                            value={fields["state"] || ""}
                          />

                          <label className="form-label form-label-select">
                            State<span className="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg">
                          {errors.state &&
                          !errors.city &&
                          !errors.address &&
                          !errors.emailid &&
                          !errors.lname &&
                          !errors.name &&
                          !errors.store_location &&
                          !errors.sales_name
                            ? errors.state
                            : null}
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-smartphone-code  icon-position"></i>

                          <input
                            type="text"
                            className="form-control"
                            placeholder="Zip Code"
                            name="zipcode"
                            maxLength="10"
                            style={
                              errors.zipcode &&
                              !errors.state &&
                              !errors.city &&
                              !errors.address &&
                              !errors.emailid &&
                              !errors.lname &&
                              !errors.name &&
                              !errors.store_location &&
                              !errors.sales_name
                                ? { border: "1px solid red" }
                                : null
                            }
                            value={fields.zipcode || ""}
                            ref={
                              errors.zipcode &&
                              !errors.state &&
                              !errors.city &&
                              !errors.address &&
                              !errors.emailid &&
                              !errors.lname &&
                              !errors.name &&
                              !errors.store_location &&
                              !errors.sales_name
                                ? ref
                                : null
                            }
                            onChange={handleChange}
                          />

                          <label className="form-label">
                            Zip Code<span className="gl-form-asterisk"></span>
                          </label>
                        </div>
                        <div className="errorMsg">
                          {errors.zipcode &&
                          !errors.state &&
                          !errors.city &&
                          !errors.address &&
                          !errors.emailid &&
                          !errors.lname &&
                          !errors.name &&
                          !errors.store_location &&
                          !errors.sales_name
                            ? errors.zipcode
                            : null}
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                        <div className="form-floating ">
                          <i className="zmdi zmdi-local-phone icon-position"></i>

                          <input
                            type="text"
                            name="phone"
                            style={
                              errors.phone &&
                              !errors.state &&
                              !errors.city &&
                              !errors.address &&
                              !errors.emailid &&
                              !errors.lname &&
                              !errors.name &&
                              !errors.store_location &&
                              !errors.sales_name
                                ? { border: "1px solid red" }
                                : null
                            }
                            className="form-control"
                            placeholder="Customer Phone#"
                            value={fields.phone || ""}
                            ref={
                              errors.phone &&
                              !errors.state &&
                              !errors.city &&
                              !errors.address &&
                              !errors.emailid &&
                              !errors.lname &&
                              !errors.name &&
                              !errors.store_location &&
                              !errors.sales_name
                                ? ref
                                : null
                            }
                            onChange={handleChange}
                            onKeyUp={onlyNumbers}
                            onPaste={pasted}
                            maxLength={10}
                          />

                          <label className="form-label">Customer Phone #</label>
                        </div>
                        <div className="errorMsg">
                          {errors.phone &&
                          !errors.state &&
                          !errors.city &&
                          !errors.address &&
                          !errors.emailid &&
                          !errors.lname &&
                          !errors.name &&
                          !errors.store_location &&
                          !errors.sales_name
                            ? errors.phone
                            : null}
                        </div>
                      </div>

                      {/* <div className="col-lg-8 col-md-7 col-sm-12 mb-3 mb-md-0 mt-0">
                        <div className="overflow-hider">
                          <ReCAPTCHA
                            sitekey={process.env.REACT_APP_API_GOOGLESITEKEY}
                            onChange={onCaptchaChange}
                          />
                        </div>
                        <div className="errorMsg">
                          {errors.checkederrorcaptcha &&
                          !errors.phone &&
                          !errors.zipcode &&
                          !errors.state &&
                          !errors.city &&
                          !errors.address &&
                          !errors.emailid &&
                          !errors.lname &&
                          !errors.name &&
                          !errors.store_location &&
                          !errors.sales_name
                            ? errors.checkederrorcaptcha
                            : null}
                        </div>
                      </div> */}
                      <div className="col-lg-4 col-md-5 col-sm-12 ">
                        <div className="d-grid">
                          <button
                            className="btn btn-primary btn-lg "
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="alert alert-info alert-info-custom">
                          Limit 1 per household, while supplies last. Some
                          restrictions apply, see store for detail.
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>

      {/* <Footer2 /> */}
    </>
  );
}
export default RegisterForm;
